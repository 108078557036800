.economic-policy .banner-text,.icprdVerticalPage .banner-text {
    top: 20px;
    bottom: unset;
  }
  
  .economic-facts {
    display: grid;
    margin: auto;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 17rem;
  }

  @media (min-width:1000px) and (max-width:1400px) {
    .economic-facts {
      padding: 1rem 8rem;
    }
     }
  .about {
    text-align: left;
  }
  .icprdVerticalPage .about li {
  list-style-type: none;
  margin-bottom: 0.5rem;
  font-size: 16px;
  text-align: justify;
  }
  
  .economic-facts > div {
    display: flex;
    gap: 1rem;
    padding: 0rem 3rem;
  }
  
  .economic-facts > div:nth-child(2) {
    border-left: 1px solid gray;
  
  }
  
  .economic-facts > div:nth-child(5) {
 
    border-right: 1px solid gray;
  }
  .economic-facts > div > h1 {
    font-size: 30px;
    color: grey;
  }
  .economic-facts > div > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
  }
  .ecopolicy {

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
  }
  
  .economicGraph {
    text-align: center;
  }
  
  .economicGraph img {
    width: 100%;
    max-width: 965px;
    margin: auto;
  }
  
  .economic-policy .banner ,.icprdVerticalPage .banner {
    background-position: center;
    height:70vh;
  }
  .climate-facts{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0rem;
    padding: 1rem 17rem;
  }
  .icprdVerticalPage .about{
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 1200px) {
    .economic-facts > div {
      padding: 0rem 1rem;
    }

  }
  @media screen and (min-width: 1400px) {
    .textfonta  {
      padding: 0rem 17rem;
    }
    .nodemake{
      padding: 1rem 17rem;
    }
    .morepaff{
      padding: 1rem 17rem; 
    }
  }
  @media (min-width:1000px) and (max-width:1400px) {
    .textfonta  {
      padding: 0rem 8rem;
    }
    .nodemake{
      padding: 1rem 8rem;
    }
    .morepaff{
      padding: 1rem 8rem; 
    }
     }

  
  @media screen and (max-width: 1000px) {
    .economic-facts > div > h1 {
      font-size: 1.5rem;
      color: grey;
    }
  
    .economic-facts > div > p {
      font-size: 16px;
      font-weight: bolder;
    }
  }
  
  @media screen and (max-width: 450px) {
    .economic-facts > div > h1 {
      font-size: 1rem;
    }
  
    .icprdVerticalPage .about{
      font-size: 1.5rem;
    }
    .economic-facts > div > p {
      font-size: 0.8rem;
    }
  

    .ecopolicy p{
      font-size: 1.5rem;
    }
    
  }
  .textfonta p{
    font-size: 16px;
    text-align: justify;
  }


  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .economic-facts {
    padding: 1rem 2rem;
}
.ecopolicy {
  width: 90%;
  margin: auto;
}
}

/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */
