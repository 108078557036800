.aboutSe h2 span {
    color: yellow;
    letter-spacing: 0px;
}
.font-text{
    font-size: 4rem;
    margin:0;
    line-height:2rem;
}
.aboutSe span{

    color: #ebaa01;
    font-size: 36px;
}
.aboutSe h1{
color:black;
font-weight: 700;
}
.board{
    border-right: 1px solid #ebaa01;
 
}
.fw-bold{
    font-weight:bold;
}
  .gallery {
            margin: 10px 40px;
        }
         
        .gallery img {
          width:100%;
            transition: 1s;
            border: 4px solid #ebaa01;
        }
         
        .gallery img:hover {
            filter: drop-shadow(4px 4px 6px gray);
            transform: scale(1.1);
        }
        .EventGallery h1{
            background: #03bdbe;
            width: 195px;
            text-align: center;
            font-family: "Optima" !important;
            font-weight: bold;
            letter-spacing: 0px;
            font-size: 28px;
            border-bottom: 5px solid #ebaa01;
            margin: auto;
            color: white;
            padding: 0.2rem 0.5rem;
            line-height: 18px;
        }