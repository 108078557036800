/* verticalsCard.css */
.verticals-card {
  width: 250px;
  /* height: 300px;  */
  
  margin: 0px;
  position: relative;
  background-color: #1c1112;

  overflow: hidden;
  /* display: flex;
      flex-direction: column;
      justify-content: flex-end; */
}

.verticals-card img {
  height: inherit;
  width: 100%;
}

.verticals-card-content {
  /* background: linear-gradient(
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.4) 25%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.8) 75%,
      rgba(0, 0, 0, 1) 100%
    ); /* Semi-transparent background for text */

  background: linear-gradient(
    rgb(0 0 0 / 0%) 0%,
    rgba(0, 0, 0, 0.4) 25%,
    rgba(0, 0, 0, 0.6) 50%,
    rgb(0 0 0 / 60%) 75%,
    rgb(0 0 0 / 67%) 100%
  );
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  bottom: 0px;
  padding: 1rem;
  text-align: left;
  height: 30%;
  color: #fff;
}

.verticals-card-heading {
  font-size: 16px;
  margin: 0;
  padding-bottom: 5px;
}
.verticals-card a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding:0px;
  text-decoration: none;
}



.verticals-card-text {
  font-size: 0.7rem;
  line-height: 0.9rem;
  margin: 0;
  padding-top: 5px;
}

@media (min-width: 1800px) and (max-width: 2000px) {
  .verticals-card-content h2 {
    font-size: 16px;
    text-align: center;
  }
  .verticals-card-text {
    font-size: 1rem;
    line-height: 1.2rem;
    
  }
  .verticals-card{
    width: 226px;
    border-radius:30px;
  }
}

@media (min-width: 1600px) and (max-width:1800px) {
  .verticals-card-content h2 {
    font-size: 16px;
    text-align: center;
}
  .verticals-card-text {
    font-size: 1rem;
    line-height: 1.2rem;
    
    
  }
  .verticals-card{
    width: 250px;
    border-radius: 6px 16px 6px 16px;
  }
}

@media screen and (max-width: 550px) {
  .verticals-card-content {
    justify-content: flex-end;
  }
}

@media screen and (max-width:1700px) {
  .verticals-card{
    gap: 1rem;
    height: 240px;
    margin: 0rem auto;
    border-radius: 6px 16px 6px 16px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all .5s;
    margin: 0px;
  }
  .verticals-card:hover{
    -webkit-transform: scale(1.03);
        transform: scale(1.03);
        cursor: pointer;
  }
  .verticals-card-content h2 {
    font-size:16px;
    text-align: center;
  }
  .verticals-card-text {
    font-size: 12px;
    line-height: 14px;
    
    
  }
}


  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .verticals-card {
    width: 45%;
    height: auto;
    margin: auto !important;
  }
}

/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

