/* EventCard.css */
.event-card {
  transition: transform 0.55s ease;
  width: 20vw;
  position: absolute;
  min-width: 300px;
  border-radius: 25px;
  margin:0px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #03bdbe;
  color: #fff;
}
.event-card-details{
  line-height: 18px;
}
.event-card-image:hover{
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.event-card-image {
  transition: all .5s;
  width: 100%;
  /* height: 300px; */
  padding-top:70%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  object-fit: contain; /* Set the height of the image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.event-card-content {
  padding: 0.5rem 1.5rem 1.5rem;
  text-align: left;
}

.event-card-heading {
  font-size: 18px;
  margin: 0.5rem 0;
  color: white;
  text-align: left;
  word-break: keep-all;
  inline-size: 271px;
  overflow-wrap: break-word;
}

.event-card-hr {
  border: none;
  height: 3px;
  width: 30%;
  background-color: #ebaa01;
  margin: 0.4rem 0;
}

.event-card-detail {
  margin: 0.2rem auto;
}

@media screen and (max-width: 425px) {
  .event-card {
    min-width: 200px;
  }
  .event-card-image {
    height: 200px;
  }
}
.eventtext{
  font-size: 16px;
}

/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .event-card {
    min-width: 270px;
  }
}
/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

