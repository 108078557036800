
.videoSection{
  padding: 1rem 0rem 0rem;
    overflow: hidden;
    
}
.videoSection header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0rem 5rem;
}
.videoSection header h1{
     line-height: 30px;
    background: #03bdbe;
    width: 160px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: auto;
    color: white;
    padding: 0.2rem 0.5rem;
}

.videoSection header p,.videoSection header h1{
    font-family: "Optima";
}

.videoSection header button{
  border: none;
  background: #900000;
  width: 100px;
  text-align: center;
  font-family: "Optima" !important;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 14px;
  border-bottom: 5px solid #ebaa01;
  margin: auto;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 30px;
}

.videoSection .video-card-list{
  transition: transform 0.3s ease-in-out;
  padding: 0.5rem;
  height: 21vw;
  justify-content: center;
  margin: 0rem 10rem;
  width: calc(100vw - 12rem);
  overflow: hidden;
  display: flex;
  position: relative;
}

.video-wrapper{
    transition: transform 0.3s ease-in-out;
    
    margin: 0rem auto;
    width: calc(100vw - 8rem);
    /* overflow: hidden; */
    display: flex;
    /* filter: drop-shadow(0 0 54.5px rgba(214,214,214,0.45)); */
    /* overflow: hidden; */
    justify-content: center;
    position: relative;
}
#v0:checked ~ .video-card-list #videoCard0,
#v1:checked ~ .video-card-list #videoCard1,
#v2:checked ~ .video-card-list #videoCard2,
#v3:checked ~ .video-card-list #videoCard3,
#v4:checked ~ .video-card-list #videoCard4 {
  transform: translate3d(0, 0, 0);
}

#v0:checked ~ .video-card-list #videoCard1,
#v1:checked ~ .video-card-list #videoCard2,
#v2:checked ~ .video-card-list #videoCard3,
#v3:checked ~ .video-card-list #videoCard4,
#v4:checked ~ .video-card-list #videoCard0 {
  transform: translate3d(105%, 0, 0px);
}

#v0:checked ~ .video-card-list #videoCard2,
#v1:checked ~ .video-card-list #videoCard3,
#v2:checked ~ .video-card-list #videoCard4,
#v3:checked ~ .video-card-list #videoCard0,
#v4:checked ~ .video-card-list #videoCard1 {
  transform: translate3d(-105%, 0, 0px);
}

#v0:checked ~ .video-card-list #videoCard3,
#v1:checked ~ .video-card-list #videoCard4,
#v2:checked ~ .video-card-list #videoCard0,
#v3:checked ~ .video-card-list #videoCard1,
#v4:checked ~ .video-card-list #videoCard2 {
  transform: translate3d(210%, 0, 0px);
}

#v0:checked ~ .video-card-list #videoCard4,
#v1:checked ~ .video-card-list #videoCard0,
#v2:checked ~ .video-card-list #videoCard1,
#v3:checked ~ .video-card-list #videoCard2,
#v4:checked ~ .video-card-list #videoCard3 {
  transform: translate3d(-210%, 0, 0px);
}

@media screen and (max-width: 425px) {
  
 .video-wrapper{
    margin: auto;
    width: 100%;
    overflow: visible;
    justify-content: center;
 }

 .video-card-list{
    position: relative;
    justify-content: center;
    display: flex;
    width: 31vw;
    height: 30vw;
 }
    }

    .video-card:hover{
      -webkit-transform: scale(1.03);
      transform: scale(1.03);
    }
    .video-card{
      transition: all .5s;
    }

 /* Further adjustments for even smaller screens if needed */
 @media screen and (max-width: 370px) {
    .videoSection{
        padding: 4rem 0rem ;
        text-align: center;
    }
    .videoSection header{
        display: block;
    }
  }
  @media screen and (max-width: 1400px) {
    .video-wrapper {
      width: calc(100vw - 7rem);
    }
}
  
  @media screen and (max-width: 1200px) {
    .videoSection .video-card-list,.videoSection .video-wrapper {
      height: 40vw;
    }

  }
  @media screen and (max-width: 900px) {
    .videoSection .video-card-list,.videoSection .video-wrapper {
      height: 350px;
    }
  }
  @media screen and (max-width: 600px) {
    .videoSection .video-card-list,.videoSection .video-wrapper {
      height: 300px;
    }
    .videoSection .video-card-list {
      margin: 0rem 0rem;
}
.videoSection header h1{
  font-size: 16px;
  width: 90px;
  float: left;
}
.videoSection header button{
  font-size: 16px;
  width: 90px;
  float: right;
}
  }

  @media (min-width: 1600px) and (max-width:2400px) {
    .video-wrapper {
      width: calc(100vw - 15rem);
    }
  }


  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .videoSection .video-card-list, .videoSection .video-wrapper {
    height: 250px;
}
  }
  
    /* //MOBILE PHONE MEDIA QUERY CSS */
  /* //MOBILE PHONE MEDIA QUERY CSS */


