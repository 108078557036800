/* Viudo carousel */

.video-section .carousel {
    height: 600px;
    overflow: hidden;
    position: relative;
    width: 100vw;
}


.video-section .carousel .list .item {
    width:0px;
    height:0px;
    position: absolute;
    top: 80%;
    transform: translateY(-70%);
    left: 70%;
    border-radius: 20px;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
    background-position: 50% 50%;
    background-size: cover;
    z-index: 100;
    transition: 1s;
}

.video-section .carousel .list .item:nth-child(1),
.video-section .carousel .list .item:nth-child(2) {
    top:0px;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.video-section .carousel .list .item:nth-child(3) {
    left: 137%;
}

.carousel .list .item:nth-child(4){
    left: calc(120% + 200px);
}

.carousel .list .item:nth-child(5){
    left: calc(127% + 400px);
}

.carousel .list .item:nth-child(6){
    left: calc(127% + 600px);
}

.carousel .list .item:nth-child(n+7){
    left: calc(127% + 800px);
    opacity: 0;
}



.video-section .list .item .content {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    width: 400px;
    text-align: left;
    color: #fff;
    display: none;
}

.video-section .list .item:nth-child(2) .content {
    display: block;
}

.video-section .content .title {
    font-size: 100px;
    text-transform: uppercase;
    color: #03bdbe;
    font-weight: bold;
    line-height: 1;

    opacity: 0;
    animation: animate 1s ease-in-out 0.3s 1 forwards;
}

.video-section .content .name {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    text-shadow: 3px 4px 4px rgba(255, 255, 255, 0.8);
    opacity: 0;
    animation: animate 1s ease-in-out 0.6s 1 forwards;
}

.video-section .content .des {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    margin-left: 5px;
    opacity: 0;
    animation: animate 1s ease-in-out 0.9s 1 forwards;
}

.video-section .content .btn {
    margin-left: 5px;
    opacity: 0;
    animation: animate 1s ease-in-out 1.2s 1 forwards;
}

.video-section .content .btn button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border: 2px solid #fff;
}

.video-section .content .btn button:nth-child(1) {
    margin-right: 15px;
}

.video-section .content .btn button:nth-child(2) {
    background: transparent;
    color: #03bdbe;
    border: 2px solid #fff;
    transition: 0.3s;
}

.video-section .content .btn button:nth-child(2):hover {
    background-color: #03bdbe;
    color: #fff;
    border-color: #03bdbe;
}


@keyframes animate {

    from {
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
    }

    to {
        opacity: 1;
        transform: translate(0);
        filter: blur(0);
    }
}

/* Carousel */



/* next prev arrows */

.video-section .arrows {
    position: absolute;
    top: 90%;
    right: 50%;
    z-index: 100;
    width: 99%;
    left: 50%;
}

.video-section .arrows button {
    width:12px;
    height: 12px;
    border-radius: 50%;
    background-color: #03bdbe;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    margin: 5px;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.video-section .arrows button:hover {
    background: #fff;
    color: #000;
}


/* time running */
.video-section .carousel .timeRunning {
    position: absolute;
    z-index: 1000;
    width: 0%;
    height: 4px;
    background-color: #03bdbe;
    left: 0;
    top: 0;
    animation: runningTime 7s linear 1 forwards;
}

@keyframes runningTime {

    from {
        width: 0%;
    }

    to {
        width: 100%;
    }

}


/* Responsive Design */

@media screen and (max-width: 999px) {

    .video-section .list .item .content {
        left: 50px;
    }

    .video-section .content .title,
    .content .name {
        font-size: 70px;
    }

    .video-section .content .des {
        font-size: 16px;
    }

}

@media screen and (max-width: 690px) {


    .video-section .list .item .content {
        top: 40%;
    }

    .video-section .content .title,
    .content .name {
        font-size: 45px;
    }

    .video-section .content .btn button {
        padding: 10px 15px;
        font-size: 14px;
    }
}
