/* @font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ThinIta.woff")
    format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-Light.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-BoldIta.woff")
    format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-RegIta.woff")
    format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtLtIta.woff")
    format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-MedIta.woff")
    format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtLt.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtBlk.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-Bold.woff")
    format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";

  src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtBdIta.woff")
    format("woff");
  font-weight: 800;
  font-style: italic;
} */

@font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-Regular.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
      font-family: 'Neusharp Bold';
      font-style: normal;
      font-weight: normal;
      src:  url('../fonts/Neusharp/NeusharpBold-7B8RV.woff') format('woff');
      }
  
      @font-face {
        font-family: 'Optima';
        font-style: normal;
        font-weight: normal;
        src: local('Optima'), url('../fonts/Optima/OPTIMA.woff') format('woff');
        }
        
    
  /* 
  @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtBlkIta.woff")
      format("woff");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-Thin.woff")
      format("woff");
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-LightIta.woff")
      format("woff");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-ExtraBold.woff")
      format("woff");
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-Medium.woff")
      format("woff");
    font-weight: 500;
    font-style: normal;
  }
  
  /* @font-face {
    font-family: "Helvetica Now Display";
  
    src: url("../fonts/HelveticalNowDisplay/HelveticaNowDisplay-HairlineI.woff")
      format("woff");
    font-weight: 100;
    font-style: italic;
  } */
  