.about-president{
    background: none;
    margin: 0rem auto;
    padding: 4rem 6rem;
    height: fit-content;
    font-family: "Optima";
   
}
.about-president> header{
    color: #382224;;
}

.about-president > header > h1{
    font-family: "Optima";
    font-size: 3.5rem;
    
}
.msg-container{
    display: flex;
    justify-content: space-around;
    gap: 3rem;
    height: 100%;
}

.about-president .profile{
    text-align: center;
}

.About-container h1{
    line-height: 30px;
    background: #03bdbe;
    width: 270px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
}

.educat .About-container h1 {
    line-height: 30px;
    background: #03bdbe;
    width: 360px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
}

.rural .About-container h1 {
    line-height: 30px;
    background: #03bdbe;
    width: 335px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
}
.trade .About-container h1 {
    line-height: 30px;
    background: #03bdbe;
    width: 310px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
}
.About-container h1 span{
    color: #F2C153;
    font-size: 2rem;
    font-weight: 200;
}
.about-president{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.about-president .profileName{
    background-color: #ebaa01;
    padding: 1rem 0rem;
    margin-top: 1rem;
}

.about-president .profileImg {
    
    overflow: hidden;
    max-height: 100%;
    width: 25rem;
}

.about-president  img {
    width: 100%;
    object-fit: contain;
    
}

.about-president .img img {
    height: 100%;
    object-fit: contain;
}

.about-president .img-container {
    display: flex;
}
.about-president .about{
    font-size: 1rem;
}
.about-president .About-container h1{
    font-size: 2rem;
    }
@media screen and (min-width: 2100px) {
.about-president.About-container h1{
font-size: 3rem;
}
}

@media screen and (max-width: 1000px) {
 .about-president{
    text-align: center;
    padding: 4rem 2rem;
 }
 .msg-container{
    flex-wrap: wrap;
    height: auto;
 }
   }

   @media screen and (max-width: 450px) {

    .About-container h1{
        font-size: 2rem;
    }
   }