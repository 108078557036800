.dignitaries {
  background-color: #ebeef1;
  margin: 0rem auto;
  padding: 4rem 4rem;
  height: fit-content;
}
.dignitaries > header {
  color: #382224;
}

.dignitaries > header > h1 {
  font-family: "Neusharp Bold";
  font-size: 3.5rem;
}
.msg-container {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  height: 28rem;
}

.dignitaries .profile {
  text-align: center;
}

.mt-6{
margin-top: 11.7rem;
}
.dignitaries .profileName {
  background: #03bdbe;
  text-align: center;
  font-family: "Optima" !important;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 23px;
  border-bottom: 5px solid #ebaa01;
  padding: 0px 10px;
  color: white;
  width: 259px;
  margin: auto;
  padding: 0.2rem 0.5rem;
  line-height: 35px;
  margin-top: 20px;
}

.dignitaries .profileImg {
  border-radius: 50%;
  overflow: hidden;
  /* max-height: 22rem; */
  width: 100%;
  margin-top: 0rem;
}
.dignitaries img:hover{
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.dignitaries img {
  transition: all .5s;
  width: 60%;
  object-fit: contain;
}

.dignitaries .img img {
  height: 100%;
  object-fit: contain;
}

.dignitaries .img-container {
  display: flex;
}

.updatedDigs .msg-container {
  flex-wrap: nowrap !important;
  overflow: visible !important;
}
.event-section {
  transition: transform 0.3s ease-in-out;
  max-width: unset;
}

.updatedDigs .event-card-wrapper {
  display: flex;
  justify-content: center;
}
/* .updatedDigs .dignitariesSection >div{
      width: 300px;
  } */

.dignitariesSection {
  display: flex;
  align-items: flex-start;
}

.zoomed-img-container {
  z-index: 1000;
  position: fixed;
  top: 0px;
  display: flex;

  height: 100vh;
  gap: 0rem;
  justify-content: center;
  object-fit: contain;
  align-items: center;
  left: 0px;

  width: 100vw;
  background: #0000009e;
}

.zoomed-img-container .zoomed-imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 99vw; */
  object-fit: contain;
  
}
.zoomed-img-container .zoomed-imgs .img img {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  /* height: 90vh; */
}

.zoomed-img-container .zoomed-imgs .img {
  border: 2px solid black;
  max-height: 90vh;
}
.zoomed-img-container .zoomed-imgs .img:first-child {
  border-right: none;
}
.zoomed-img-container .zoomed-imgs .img:nth-child(2) {
  border-left: none;
}

@media screen and (min-width: 2100px) {
  .updatedDigs .profile {
    width: 500px;
  }
  .updatedDigs .img-container img {
    width: 600px;
  }
}
@media (max-width: 2100px) and (min-width: 1900px) {
  .updatedDigs .profile {
    width: 450px;
  }
  .updatedDigs .img-container img {
    width: 312px;
  }
  .mt-6 {
    margin-top: 17.7rem;
}
}

@media (max-width: 1900px) and (min-width: 1300px) {
  .updatedDigs .profile {
    width: 300px;
  }
  .updatedDigs .img-container img {
    width: 221px;
  }
  .dignitariesSection {
    justify-content: center;
    gap: 2rem;
  }

}
@media (max-width: 1300px) and (min-width: 1000px) {
  .updatedDigs .profile {
    width: 200px;
  }
  .updatedDigs .img-container img {
    width: 155px;
  }
  .dignitariesSection {
    justify-content: center;
    gap: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .updatedDigs .profile {
    width: 200px;
  }
  .updatedDigs .img-container img {
    width: 215px;
  }
  .dignitaries {
    text-align: center;
  }
  .dignitaries-section {
    width: 1200px;
  }
  .msg-container {
    flex-wrap: wrap;
    height: auto;
  }
  .updatedDigs .msg-container {
    flex-wrap: nowrap !important;
    overflow: visible !important;
  }
}

@media screen and (min-width: 1000px) {
  .zoomed-img-container .zoomed-imgs {
    height: 90vh;
  }
}

@media screen and (max-width: 1000px) {
  .dignitariesSection {
    flex-direction: column;
  }
}
@media screen and (max-width: 700px) {
  .updatedDigs .img-container img {
    width: 150px;
  }

}

@media screen and (max-width: 450px) {
  .updatedDigs .msg-container {
    flex-wrap: nowrap;
    overflow: visible;
  }
  .updatedDigs {
    padding: 1rem;
  }
}

  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .dignitariesSection {
    align-items: center;
  }
  .dignitaries img {
    width: 100%;
}
.mt-6 {
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
}


}

/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

