.dignitaries{
    background-color: #f7f7f7;
    margin: 2rem auto;
    padding: 2rem 0rem;
    height: fit-content;
}
.dignitaries> header{
    color: #382224;;
    /* padding: 0rem 6rem; */
}

.dignitaries > header > h1{
    font-family: "Neusharp Bold";
    font-size: 3.5rem;
    
}
.msg-container{
    display: flex;
    justify-content: space-around;
    gap: 3rem;
    height: 28rem;
}

.dignitaries .msg-container{
    justify-content: center;
}

.dignitaries .profile{
    text-align: center;
}

.dignitaries .profileName{
    background: #03bdbe;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 23px;
    border-bottom: 5px solid #ebaa01;
    padding: 0px 10px;
    color: white;
    width: 175px;
    margin: auto;
    line-height: 35px;
    margin-top: 20px;
    padding: 0.2rem 0.5rem;
}

.dignitaries .profileImg {
    border-radius: 50%;
    overflow: hidden;
    /* max-height: 22rem; */
    width: 20rem;
    margin-top: 0rem;
}

.dignitaries  img {
    width: 100%;
    object-fit: contain;
    
}

.dignitaries .img img {
    height: 100%;
    object-fit: contain;
}

.dignitaries .img-container {
    display: flex;
}


@media screen and (max-width: 1000px) {
 .dignitaries{
    text-align: center;
 }
 .msg-container{
    flex-wrap: wrap;
    height: auto;
 }
   }

   .fontforms{
    background: #03bdbe;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 23px;
    border-bottom: 5px solid #ebaa01;
    padding: 0.2rem 0.5rem;
    color: white;
    line-height: 26px;
   }

.carousel-control-prev-icon {
    background-image: url('../../../assets/images/HOMEPAGE/left.png');
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 35px;
    height: 35px;
}
.carousel-control-next-icon {
    background-image: url('../../../assets/images/HOMEPAGE/right.png');
}
.carousel-control-next, .carousel-control-prev {
    top: 202px;
}
.carousel-control-next {
    left: 48px;
}
.visually-hidden{
    visibility: hidden;
}