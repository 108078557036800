.main {
  width: 100vw;
  background-image: linear-gradient(
    0deg,
    #000000 0%,
    #d3d3d3 79%,
    #ffffff 100%
  );
  padding: 5px;
}
.home h1 {
  font-family: "Optima" !important;
  font-weight: bold;
  text-transform: uppercase;
}

/* HeaderImage.css */
.header-image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.header-image-container > div:first-child {
  width: 100%;
}

.video-box {
  display: flex;

  flex: 1;
}

.scroll-indicator {
  width: 250px;
  background: none;
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 6rem;
  border-top: 2px solid #03bdbe;
  text-align: center;
  color: #fff; /* Text color */
  font-size: 0.9rem; /* Adjust font size as needed */
  transform: rotate(-90deg); /* Rotate the text by 90 degrees */
  transform-origin: left bottom; /* Rotate from the left bottom corner */
}

.scroll-indicator div {
  width: fit-content;
  text-align: left;
  border-top: 5px solid #03bdbe;
}

section {
  width: 100vw;
  background-image: linear-gradient(0deg, #000000 0%, #000000 100%);
  color: white;
}

.header-content {
  margin: 0px 5rem;
  /* margin-right: 5rem; */
  padding-top: 4rem;
}

/* .header-content h1{
  text-align: left;
  margin:  0rem auto;
} */

section h1 {
  text-align: center;
  padding-top: 1rem;
  width: fit-content;
  font-size: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Optima";
  margin: auto;
}

video {
  width: 100%;
  /* max-width: 1000px; */
  display: block;

  /* margin: 20px auto; */
}

.dynamicHr .dark-hr {
  background-color: #bfbfbf;
}

.dynamicHr .light-hr {
  background-color: #ffffff33;
}

/* .slider-hr {
  position: absolute;
  width: 100%;
  z-index: 0;
  border: 1px solid #29282830;
  white-space: 3px;
  top: 3px;
} */

.carousel-dot {
  width: 15px;
  height: 8px;
  background-color: black;
  border-radius: 5px;
  margin: 0 1px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
}
.p-carousel-prev,
.p-carousel-next {
  display: none;
}

.p-carousel-indicators{
  background: linear-gradient(to bottom, white 0%, white 40%, grey 40%, white 50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.p-carousel-indicator button{
  width: 15px;
    height: 8px;
    background-color: black;
    border-radius: 5px;
    margin: 0 1px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    z-index: 1;

}

.p-highlight button{
  background-color: rgb(236, 164, 4);
    width: 35px;
}
.home .aboutSection{
  padding: 4rem 0rem;
}
/* Adjust font size for smaller screens */
@media screen and (max-width: 1200px) {
  section h1 {
    font-size: 2.5rem;
  }

  .video-box {
    justify-content: center;
  }

  video {
    margin: 20px auto;
    position: unset;
    width: 100%;
  }

  .about-section {
    display: block;
  }
  .about-uplift {
    top: 0rem;
  }
  .card-container {
    justify-content: center;
  }
}

/* Adjust font size for smaller screens */
@media screen and (max-width: 1100px) {
  .footerVerticals {
    display: none;
  }
  .features {
    display: none;
  }
}
/* Adjust font size for smaller screens */
@media screen and (max-width: 768px) {
  .scroll-indicator{
    display: none;
  }
}

/* Further adjustments for even smaller screens if needed */
@media screen and (max-width: 476px) {
  .header-content h1{
    text-align: left;
  }
}



  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {

.header-content {
  margin: 0px 2rem;
}

}