.aboutUs .banner-text{
    top: 20px;
    bottom: 20px;
    ;
}
.aboutUs{
    font-family: "Optima";
}

.about-text{
padding: 1rem 8rem;
padding-bottom: 1rem;
}
@media (min-width:1000px) and (max-width:1400px) {
    .about-text{
        padding: 1rem 8rem;

    }
}



.aboutUs .vision-about .msg-container{
    align-items: center;
    padding: 0rem 8rem;
}
@media (min-width:1000px) and (max-width:1400px) {
    .aboutUs .vision-about .msg-container{
        padding: 0rem 8rem;
    }
     }
.aboutUs .mission-about .msg-container{
    align-items: center;    
}
.mission-about {
    background-color: #fffbf2;
}

.mission-about  .profile {
    order: 1; /* Set a higher order for the image to make it appear on the left */
    flex: 1; /* Allow the image to expand to fill available space */
}

.mission-about .About-container {
    order: 2; /* Set a lower order for the text to make it appear on the right */
    flex: 1; /* Allow the text to expand to fill available space */
}
.mission-about .about li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.aboutUs .banner {
    background-position: center;
  }


  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */



  @media (max-width: 900px) and (min-width: 200px) {
    .about-text {
        padding: 1rem 2rem;
        text-align: justify;
    }
    .aboutUs .vision-about .msg-container {
        padding: 0rem 2rem;
}


  }

  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */