.aboutUs .msg-container {
    display: flex;
    justify-content: center;
    gap: 3rem;
    height: 100%;
    padding: 1rem 8rem;
  }

  @media (min-width:1000px) and (max-width:1400px) {
 .aboutUs .msg-container {
    padding: 1rem 8rem;
  }
  }

  .aboutUs .profile {
    text-align: center;
    width: 100%;
    flex: 1;
  }
  
  .nodemake .About-container h1 {
    line-height: 30px;
    background: #03bdbe;
    width: 665px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
  }
  
  .About-container h1 span {
    color: #f2c153;
    font-size: 2rem;
    font-weight: 600;
  }
  .About-container h2 {
    font-weight: bolder;
    font-family: "Helvetica Now Display";
    letter-spacing: 0.5px;
    margin-bottom: 1.5rem;
  }
  .aboutUs .profileName {
    background-color: #ebaa01;
    padding: 0.5rem 0rem;
    margin-top: 1rem;
  }
  .profileName h2{
    font-size: 20px;
    margin: 0rem;
    font-weight: bold;
  }
  .aboutUs .profileImg {
    overflow: hidden;
    max-height: 100%;
  }
  
  .aboutUs .msg-container img {
    width: 100%;
    object-fit: contain;
  }
  .vision-about .msg-container .popimg {
    width: 50%;
    object-fit: contain;
  }
  /* .aboutUs img {
    height: 100%;
    object-fit: contain;
  } */
  
  .aboutUs .img-container {
    display: flex;
  }
  
  .aboutUs .about {
    line-height: 1.5rem;
    font-family: "Optima";
    color: black;
    font-weight: 500;
    text-align: justify;
  }
  
  .pad-4-s-2{
      padding: 4rem;
  }
  
  .aboutUs .About-container{
    flex: 1;
  
  }

  @media screen and (min-width: 1800px) {
    .aboutUs .About-container h2{
      font-size: 4rem;
    }
    .aboutUs .About-container h2{
      font-size: 2.5rem;
    }
    .aboutUs .About-container .about{
      font-size: 16px;
      line-height: 34px;
    }
    .aboutUs .about-text h2{
     font-size: 26px;
    }
    .aboutUs .about-text p{
      font-size: 16px;
    }
    .mission-about .about li{
      font-size: 16px;
      line-height: 30px;
    }
  }


  @media screen and (max-width: 1000px) {
    .aboutUs {
      text-align: center;
    }
  .aboutUs .msg-container{
    flex-direction: column;
  }
    .mission-about .msg-container {
      flex-direction: column;
    }
    .mission-about .msg-container .about li {
      text-align: left;
    }
    .msg-container {
      flex-wrap: wrap;
      height: auto;
    }
  }
  
  @media screen and (max-width: 450px) {
    .About-container h1 {
      font-size: 2rem;
    }
    .pad-4-s-2{
      padding: 2rem;
  }
  }
  
  .navbar-menu li:nth-child(1){
    padding:0px 15px; 
    background:#03bdbe;
  }
  .submenu li{
    padding:0px 0px !important; 
  }

  .About-contain h1 {
    line-height: 30px;
    background: #03bdbe;
    width: 320px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 28px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    padding: 0.2rem 0.5rem;
}


/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */


@media (max-width: 900px) and (min-width: 200px) {
  .aboutUs .msg-container {
    padding: 1rem 2rem;
}
.vision-about .msg-container .popimg {
  width: 100%;
}
.nodemake .About-container h1 {
  width: 100%;

}
}

/* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */