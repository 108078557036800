.box-sdow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}


.tab-ined .nav-tabs .nav-link {
    border: 1px solid lightgray;
    padding: 6px 6px;
    border-radius: 50px;
    width: 180px;
    font-size: 11px;
    margin-bottom: 10px;
    background: #03bdbe;
    color: white;
    font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: #ebaa01;
    color: white;
    border: 1px solid;
    padding: 3px 20px;
    border-radius: 12px;
}
.nav:before{
    display: none;
}

.tab-ined .nav-fill{
    border-bottom: 0px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    justify-content: center;
    gap: 0px;
}
.tab-ined .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    line-height: normal !important;
    background: #515555 !important;
    text-align: center !important;
    font-family: "Optima" !important;
    font-weight: bold !important;
    letter-spacing: 0px;
    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    width:180px !important;
    padding: 6px 3px !important;
    font-size: 11px !important;
    border-radius: 50px;
}
@media (min-width: 2000px) {
    .nav-tabs .nav-link {
        width: 355px;
    }

  }

  @media (min-width: 1800px) {

    .smaller {
        font-size: 12px !important;
        background: maroon;
        padding: 3px 10px;
        border-radius: 12px;
        color: white;
        width: 99px;
    }
    .pain{
        margin-top: -5px;
    }

  }

@media (max-width:1200px) {

    .nav-tabs .nav-link {
        width: 240px;
}
.tab-ined .nav-fill {
    grid-template-columns: repeat(4, 1fr);
}
}

@media (max-width:565px) {

    .nav-tabs .nav-link {
        padding: 12px 20px;
        width: 185px;
        margin-bottom: 10px;
}
    
}

@media (max-width:400px){
.nav-tabs .nav-link {
        padding: 12px 0px;
        width: 140px;
        margin-bottom: 10px;
}
}

.box-sdow .card-body{
    background-image: url('../../assets/images/backevent.jpg');
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: auto;
}
.card-title{
  text-align: center;
  margin-bottom: 0px;
}
.card-text{
    text-align: center;
}
.card-img-top {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
}

.dflex .card-img-top{
    height: 125px;
    object-fit: contain;
    background: #eca404;
}
.dflex .card-text{
padding-left: 10px;
}
.dflex .card{
    border-radius: 20px;
}
.card p{
    font-size: 14px;
}

.pain{
    margin-top: -5px;
}

  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
.card-img-top {
    height: auto;;
}
.doml .col-sm-6{
margin:10px auto
}
.tab-ined .nav-fill {
    grid-template-columns: repeat(2, 1fr);
}
.tab-ined .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    line-height: normal !important;
}
}

  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */