.footer{
    margin-top: 5rem;
    font-family: "Helvetica Now Display","sans-serif";
    font-weight: 500;
    font-size: 1rem;
}

.org-title{
    background-color:  #f6f6f6;;
    display: flex;
    justify-content: center;
    align-items: center;
}

.org-title img{
    width: 18rem;
}

.org-title span{
    
    color: #8C1A1B;
    border-left: 2px solid #8C1A1B;
    padding: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: auto;
    line-height: 2rem;
}
.org-title img{
    height: 7rem;
    width: auto;object-fit: contain;
}

.org-details{
    display: flex;

}
.footer-links li a:hover{
    text-decoration: none;
    color: #FFD400 
}
.social-links a:hover{
    text-decoration: none;
    color: #FFD400
}
.org-address{
    flex: 1 1;
    padding: 3rem 0rem 0rem 4rem;
    background-color: #000;
    color: white;
}

.address p{
    padding: 2px;
    text-align: left;
    font-size: 14px;
}

.contact-info{
    margin-top: 10px;
}
.contact-info p a:hover{
color:#f5b704;
}
.contact-info p{
    padding: 0px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.org-address2 {
    flex: 1 1;
    padding: 3rem 1rem 0rem 0rem;
    background-color: #000;
    color: white;
}
.contact-info i {
    font-size: 16px;
    background-color: #f5b704;
    padding: 2px 7px 2px 7px;
    color: #232323;
    margin: 0 0 0px 0;
    border-radius: 20px;
}

.org-links-container{
    flex:1;
  padding: 2rem 4rem;
    background-color: #ebaa01;
    
}


.contact-info img {
    font-size: 16px;
    background-color: #f5b704;
    padding: 2px 4px 2px 4px;
    color: #232323;
    margin: 0 0 0px 0;
    border-radius: 20px;
    width: 30px;
    height: 26px;
}




.socialmedia li a .fab {
    color: #FFD400;
    font-size: 30px;
    padding-right: 10px;
}



.org-links{
    max-width: 500px;
    margin: auto;
background-color: #ebaa01;;
}
.footer-links {
  
  font-weight: bold;
  }

  .footer-links{
    list-style: none;
    /* border-left: 2px solid black; */
  }

  .footer-links li {
    padding: 0rem 0.5rem;
    padding-bottom: 10px;
    position: relative;
    line-height: 1.2;
  }

.page-links a {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
    font-size: 14px;

}
.page-links{
    white-space: nowrap;
}

.org-address h4 {
    color: #fff;
    position: relative;
    width: auto;
    margin-bottom: 14px;
    display: inline-block;
    font-size: 20px;
    border-bottom: 2px solid #ffd600;
    padding-bottom: 10px;
}
.org-address2 h4 {
    color: #fff;
    position: relative;
    width: auto;
    margin-bottom: 14px;
    display: inline-block;
    font-size: 20px;
    border-bottom: 2px solid #ffd600;
    padding-bottom: 10px;
}
.social-links{
    padding: 0rem;
    display: flex;
    margin-top:0rem;
    flex-direction: column;
    font-size: 14px;
    gap: 1rem;
    justify-content: center;
}
.social-links img{
    width: 35px;
    margin-right: 10px; 
}

.copyright{
    background-color: #252525;
    color:#ffffff;
    display: flex;
    justify-content: space-between;
    padding:1rem 3rem;
    letter-spacing: 0.5px;
    font-size: 14px
    ;
}

/* Adjust font size for smaller screens */
@media screen and (max-width: 1000px) {
    .org-details{
        flex-wrap: wrap;
    }
    .address{
        text-align: center;
    }
}
/* Adjust font size for smaller screens */
@media screen and (max-width: 768px) {
    .copyright{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .org-address2{
        padding: 3rem 0rem 0rem 4rem
    }
}

/* Further adjustments for even smaller screens if needed */
@media screen and (max-width: 576px) {
  .copyright{
    font-size: 0.7rem;
  }
  .social-links img{
    width: 40px;
    
}
.org-title span{
    font-size: 1rem;
}

.org-links-container{
    padding: 2rem;
}

.contact-info{
    text-align: left;
}
}
.footer-links li a{
    padding: 0px;
}



  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
    .contact-info p {
        font-size: 12px !important;
    }
    }
    
      /* //MOBILE PHONE MEDIA QUERY CSS */
    /* //MOBILE PHONE MEDIA QUERY CSS */


