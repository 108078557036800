.features {
  width: 100vw;

  background-color: black;
}
.featuresContainer {
  max-width: 100vw;
  height: 25vw;
  padding-bottom: 0rem;
  display: flex;
  width: 25vw;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

/*.featuresContainer > div:nth-child(even){
    background-color: rgb(20, 20, 20);
}
*/

/* Carousel Dots */
.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top:0px;
  position: relative;
}

.features .carousel-dot {
  width: 15px;
  height: 10px;
  background-color: #ccc;
  border-radius: 7px;
  margin: 0 1px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  z-index: 10;
}

.carousel-dot.active-dot {
  background-color: rgb(236, 164, 4);
  width: 35px; /* Change to your desired active dot color */
}
/* Carousel Wrapper */
.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.features.slider-hr {
  position: absolute;
  width: 100%;
  z-index: 0;
  border: 1px solid #ffffff2e;

  top: 3px;
}

.d-none {
  display: none;
}

#f0:checked ~ .featuresContainer #feature0,
#f1:checked ~ .featuresContainer #feature1,
#f2:checked ~ .featuresContainer #feature2 {
  transform: translate3d(0, 0, 0);
  background-color: rgb(0 0 0);
}
#f0:checked ~ .featuresContainer #feature1,
#f1:checked ~ .featuresContainer #feature2,
#f2:checked ~ .featuresContainer #feature1{
  transform: translate3d(100%, 0, 0px);
}
#f0:checked ~ .featuresContainer #feature2,
#f1:checked ~ .featuresContainer #feature0,
#f2:checked ~ .featuresContainer #feature0{
  transform: translate3d(-100%, 0, 0px);
}