.small{
  font-size: 12px !important;
}
.smallText{
  font-size: 12px !important;
  font-weight: bold;
}
.CardSmall{
  font-size: 20px;

}
.mediapost .smaller{
  font-size: 14px !important;
  background: maroon;
  padding: 5px 20px;
  border-radius: 12px;
  color: white;
}

.mediapost .smaller {
  font-size: 12px !important;
  background: maroon;
  padding: 3px 10px;
  border-radius: 12px;
  color: white;
  width: 110px;
}
.boxShadow{
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mediapost .card-text:last-child {
  margin-bottom: 0;
  text-align: left;
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 11px;
}

.mediapost .card-img-top {
  display: block;
  width: 100%;
  height: 140px;
  object-fit: fill;
}