#chartdiv {
    width: 100%;
    height: 300px;
  }
  
  @media screen and (max-width: 900px) {
#chartdiv{
    height:100% !important;
}

  }