.economic-policy .banner-text,.icprdVerticalPage .banner-text {
    top: 20px;
    bottom: unset;
  }
  
  
  .about {
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  
  .economic-facts > div {
    display: flex;
    gap: 1rem;
    padding: 0rem 3rem;
  }
  

  
  .economicGraph {
    text-align: center;
  }
  
  .economicGraph img {
    width: 100%;
    max-width: 965px;
    margin: auto;
  }
  


  .icprdVerticalPage .about{
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 1200px) {
    .economic-facts > div {
      padding: 0rem 1rem;
    }
  }
  

  
  @media screen and (max-width: 450px) {
  
    .icprdVerticalPage .about{
      font-size: 1.5rem;
    }

  

  }
  .first-l{
    padding-left: 20px;
  }
  .textbo{
    text-decoration: underline 3px #ebaa01;
    text-underline-offset: 2px;
    text-underline-position: under;
  }

  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
  .mutor{
    width:100% !important;
  }

}