@import url("./assets/themes/font.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  font-size: 20px;
}
label {
  font-weight: 300;
}
body {
  font-family: "Helvetica Now Display", "sans-serif";
  max-width: 100vw;
  scrollbar-width: thin; /* Adjust the width to your preference */
  overflow-x: hidden;
  font-size: 16px;
  font-weight: normal;
}

/* WebKit (Chrome, Safari, newer versions of Opera) */
/* WebKit (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Adjust the width of the horizontal scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  /* Adjust the width here to make it thinner */
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Microsoft Edge and IE */
body {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* customscrollabr */
.card-list {
  scrollbar-width: thin; /* for Firefox */
  scrollbar-color: transparent transparent; /* for Firefox */
}
/* For Chrome, Safari, and Edge */
.card-list::-webkit-scrollbar {
  width: 24px; /* Doubled the width */
}
.card-list::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.card-list::-webkit-scrollbar-thumb {
  background-image: radial-gradient(circle, #ebaa01 50%, transparent 45%),
    radial-gradient(circle, black 50%, transparent 60%),
    radial-gradient(circle, black 50%, transparent 60%),
    radial-gradient(circle, black 50%, transparent 60%);
  background-size: 56% 40%, 19.67% 40%, 19.67% 40%, 19.67% 40%;
  background-repeat: no-repeat;
  background-position: 0% 50%, 50% 50%, 66.67% 50%, 83.34% 50%;
}

.card-list::-webkit-scrollbar-track {
  background: linear-gradient(
    to bottom,
    transparent,
    transparent 45%,
    #797979 45%,
    white 55%,
    transparent 55%,
    transparent
  );

  background-clip: padding-box;
  border-radius: 12px; /* Adjusted to match the scrollbar's width */
}
g[opacity="0.4"] {
  display: nne;
}
button {
  cursor: pointer;
}
a{
  color: inherit;
  text-decoration: none;
}

@media (min-width: 2000px) {
  html {
    font-size: 28px; /* Adjust the base font size for smaller screens */
  }
}

@media (max-width: 1600) and (min-width: 1800px) {
  html {
    font-size: 24px;
  }
}

@media (max-width: 1800) and (min-width: 1400px) {
  html {
    font-size: 20px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 16px; /* Adjust the base font size for smaller screens */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Adjust the base font size for smaller screens */
  }
}

/* Further adjustments for even smaller screens if needed */
@media screen and (max-width: 576px) {
  html {
    font-size: 10px;
  }
}
.para{
  font-size:16px;
}
.IN:hover + .hide {
  display: block;
  color: red;
}
.news-overlay .banner .banner-text{
  background: rgb(0 0 0 / 50%);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
