.box-sdow {
  box-shadow: none !important;
  border: 1px solid;
}

.morevent .nav-link{
    font-weight: 800;
    font-size: 20px;
}
.nav-tabs .nav-link {
    border: 1px solid lightgray;
    padding: 6px 20px;
    border-radius: 50px;
    width: 255px;
    font-size: 14px;
    margin-bottom: 10px;
    background: #03bdbe;
    color: white;
    font-weight: bold;
}
.fade {
    opacity: 1;
}
.fade:not(.show) {
    opacity: 1;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    line-height: 30px;
    background: #515555;
    width: 230px;
    text-align: center;
    font-family: "Optima" !important;
    font-weight: bold;
    letter-spacing: 0px;

    border-bottom: 5px solid #ebaa01;
    margin: 0px;
    color: white;
    width: 255px;
    padding: 3px 20px;
    font-size: 14px;
    border-radius: 50px;
}
@media (min-width: 2000px) {
    .nav-tabs .nav-link {

        width: 355px;

    }
  }




@media (max-width:1200px) {

    .nav-tabs .nav-link {
        width: 240px;
}


.heightbag .banner{
    height: 130vh !important;
}
    
}

@media (min-width:900px) {

    .dflex{
        display: flex;
    }

}

@media (max-width:768px) {

    .heightbag .banner{
        height: 150vh !important;
    }

}
@media (max-width:565px) {

    .nav-tabs .nav-link {
        padding: 12px 20px;
        width: 185px;
        margin-bottom: 10px;
}



.heightbag .banner{
    height: 100vh !important;
}
    
}

@media (max-width:400px){
.nav-tabs .nav-link {
        padding: 12px 0px;
        width: 140px;
        margin-bottom: 10px;
}



.heightbag .banner{
    height: 100vh !important;
}
}

b, strong {
    font-weight: bolder;
    font-family: 'Helvetica Now Display';
    color: black;
}
.w-60{
    width: 60%!important; 
}
.heightbag .banner{
    height: 100vh;
}
.aboutS span {
    color: white;
}
.aboutS h1 {
    color: white;
    font-weight: 700;
}
.w-80{
    width:80%
}
.border-bottom2{
    background: #ffb000e8;
    position: relative;
}



.boardss {
    border-right: 1px solid white;
}
.boardss h3{
    color: white;
    font-weight: bold;
}
.aboutS h5{
    color: white !important;
}
.boardss h4{
    color: white;
}
.time{
padding-left: 10px;
}
.dflex .card-text{
    text-align: left;
}
.dflex .card-body{
padding-left: 5px !important;
padding-right: 5px !important;
}







  /* //MOBILE PHONE MEDIA QUERY CSS */
/* //MOBILE PHONE MEDIA QUERY CSS */

@media (max-width: 900px) and (min-width: 200px) {
    .heightbag .banner {
        height: 20vh !important;
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        width: 130px;
            padding: 3px 7px;
            font-size: 10px;
  
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    width: 130px;
    padding: 3px 2px;
    font-size: 10px;
    line-height: 29px;
}
.dflex {
    width:50%;
    margin: 10px auto;
    display: flex;
}
.card-title {
    font-size: 13px;
}
.card p {
    font-size: 12px;
}
.w-80 {
    width: 100%;
}

.morert{
    width: 25%;
}

.morert1{
    width: 75%;

}

  }

  /* //MOBILE PHONE MEDIA QUERY CSS */
  /* //MOBILE PHONE MEDIA QUERY CSS */
